<template>
  <el-dialog
    title="重要信息确认"
    :visible.sync="show"
    :show-close="false"
    :append-to-body="true"
    width="40%"
    :close-on-click-modal="false"
  >
    <el-form
      :model="reEduForm"
      ref="reEduForm"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
    >
      <div class="time_group">
        <el-form-item label="入校时间" prop="rxny" class="w-50">
          <el-date-picker
            v-model="reEduForm.rxny"
            type="date"
            class="w-100"
            placeholder="入校时间"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="jsxyny" class="w-50">
          <el-date-picker
            v-model="reEduForm.jsxyny"
            type="date"
            class="w-100"
            placeholder="结束时间"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="学校名称" prop="byyxxhdw">
        <el-input
          type="text"
          v-model="reEduForm.byyxxhdw"
          placeholder="请输入学校名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="学历层次" prop="xlm">
        <el-select
          v-model="reEduForm.xlm"
          class="w-100"
          placeholder="请选择学历"
          value-key="name"
          @change="xlChange($event)"
        >
          <el-option-group
            v-for="(v, i) in xlList"
            :key="i"
            :label="v.lable.name"
          >
            <el-option
              v-for="item in v.options"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="学历证书号" prop="xlzsh">
        <el-input
          type="text"
          v-model="reEduForm.xlzsh"
          placeholder="输入学历证书号"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="所学专业" prop="sxzymc">
        <el-input
          type="text"
          v-model="reEduForm.sxzymc"
          placeholder="请输入所学专业"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="recheck">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    form: Object,
    show: Boolean,
    sfdrEdu: {
      type: Boolean,
      default: false,
    },
    xlDict: {
      type: Object,
      default: () => {
        return {};
      },
    },
    xlList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {
    if (this.sfdrEdu) {
      for (const val in this.xlDict) {
        this.reEduForm[this.xlDict[val]] = this.form[this.xlDict[val]];
      }
    }
  },
  data() {
    const checkrxny = (rule, value, cb) => {
      if (this.form["rxny"] == value) {
        return cb();
      }
      cb(new Error("输入不一致"));
    };
    const checkjsxyny = (rule, value, cb) => {
      if (this.form["jsxyny"] == value) {
        return cb();
      }
      cb(new Error("输入不一致"));
    };
    const checkbyyxxhdw = (rule, value, cb) => {
      if (this.form["byyxxhdw"] == value) {
        return cb();
      }
      cb(new Error("输入不一致"));
    };
    const checksxzymc = (rule, value, cb) => {
      if (this.form["sxzymc"] == value) {
        return cb();
      }
      cb(new Error("输入不一致"));
    };
    const checkxlm = (rule, value, cb) => {
      if (this.form["xlm"] == value) {
        return cb();
      }
      cb(new Error("输入不一致"));
    };
    const checkxlzsh = (rule, value, cb) => {
      if (this.form["xlzsh"] == value) {
        return cb();
      }
      cb(new Error("输入不一致"));
    };
    return {
      reEduForm: {
        rxny: "",
        jsxyny: "",
        byyxxhdw: "",
        sxzymc: "",
        xlm: "",
        xlzsh: "",
      },
      rules: {
        rxny: [
          {
            required: true,
            message: "请选择入校时间",
            trigger: "change",
          },
          {
            validator: checkrxny,
            trigger: "change",
          },
        ],
        jsxyny: [
          {
            required: true,
            message: "请选择结束学业时间",
            trigger: "change",
          },
          {
            validator: checkjsxyny,
            trigger: "change",
          },
        ],
        byyxxhdw: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "change",
          },
          {
            validator: checkbyyxxhdw,
            trigger: "change",
          },
        ],
        xlm: [
          {
            required: true,
            message: "选择学历",
            trigger: "change",
          },
          {
            validator: checkxlm,
            trigger: "change",
          },
        ],
        xlzsh: [
          {
            required: true,
            message: "输入学历证书号",
            trigger: "change",
          },
          {
            validator: checkxlzsh,
            trigger: "change",
          },
        ],
        sxzymc: [
          {
            required: true,
            message: "请填写学习专业",
            trigger: "change",
          },
          {
            validator: checksxzymc,
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    recheck() {
      this.$refs["reEduForm"].validate((valid) => {
        if (valid) {
          this.$emit("success");
        }
      });
    },
    close() {
      this.$emit("close");
    },
    change(e) {
      console.log(e);
      this.$forceUpdate();
    },
  },
  watch: {
    sfdrEdu: {
      handler(val) {
        if (val) {
          for (const val of this.xlDict) {
            this.reEduForm[val] = this.form[val];
          }
        }
      },
    },
  },
};
</script>

<style>
.time_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
